const ApplicationStore = () => {

  function setStorage(storageKey, storageData) {
    sessionStorage.setItem(storageKey, JSON.stringify(storageData));
  }

  function getStorage(storageKey) {
    const dataObject = sessionStorage.getItem(storageKey) ? JSON.parse(sessionStorage.getItem(storageKey)) : '';
    return dataObject;
  }

  function clearStorage() {
    sessionStorage.clear();
  }

  function standardizeCoordinates(coordinates) {
    if (!coordinates || !Array.isArray(coordinates) || coordinates.length !== 2) {
      throw new Error('Invalid coordinates format');
    }

    let [latitude, longitude] = coordinates.map(coord => parseFloat(coord));

    if (isNaN(latitude) || isNaN(longitude)) {
      throw new Error('Coordinates must be numbers');
    }

    if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
      throw new Error('Coordinates out of range');
    }

    return { latitude, longitude };
  }

  return {
    setStorage,
    getStorage,
    clearStorage,
    standardizeCoordinates,
  };
};

export default ApplicationStore;
