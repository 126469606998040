import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ApplicationStore from '../Utility/localStorageUtil';

const UtilityForManage = ({ selectedData }) => {
    const [unitType, setUnitType] = useState('');

    const [invNo, setInvNo] = useState('');
    const [invDate, setInvDate] = useState('');
    const [device_type, Setdevice_type] = useState('');

    const [afiliateCode, SetafiliateCode] = useState('');


    useEffect(() => {
        setUnitType(selectedData?.selectedData?.deviceId || '');
        Setdevice_type(selectedData?.selectedData?.deviceType || '')
        SetafiliateCode(selectedData?.selectedData?.afiliateCode || '')
        setInvNo(selectedData?.selectedData?.invNo || '')
        setInvDate(selectedData?.selectedData?.invDate || '')

    }, []);
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6.3}>
                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Unit Serial No:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.deviceId || '---'}
                        </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Device Type:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.deviceType || '---'}
                        </Typography>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            User Name:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.userName || '---'}
                        </Typography>

                    </div>

                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            User Email:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.email || '---'}
                        </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Company Name:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.companyName || '---'}
                        </Typography>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Contact No:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.phoneNo || '---'}
                        </Typography>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Customer Address:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.address || '---'}
                        </Typography>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Customer City:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.city || '---'}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Affiliate Code:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold' }}>
                            {selectedData?.afiliateCode || '---'}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} md={5.7}>
                    <div style={{ display: "flex", }}>
                        <Typography style={{
                            display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro',
                            fontWeight: 'bold',
                        }}>
                            Invoice No:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            {selectedData?.invNo || '---'}
                        </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            Invoice Date:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>
                            {selectedData?.invDate || '---'}
                        </Typography>

                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography style={{
                            display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro',
                            fontWeight: 'bold',
                        }}>
                            First Start Date:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>{new Date(selectedData?.created_at).toLocaleDateString()}</Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography style={{
                            display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro',
                            fontWeight: 'bold',
                        }}>
                            Last Used Date:
                        </Typography>
                        <Typography style={{ display: 'flex', fontSize: '16px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginRight: "9px" }}>{new Date(selectedData?.updated_at).toLocaleDateString()}</Typography>
                    </div>
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '14pt',  fontFamily: 'Readex Pro',
                                    fontWeight: 'bold', }}>
                                Invoice No
                            </Typography>

                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '14pt',  fontFamily: 'Readex Pro',
                                    fontWeight: 'bold', }}>
                                Invoice Date.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '14pt',  fontFamily: 'Readex Pro',
                                    fontWeight: 'bold', }}>
                                First Start Date
                            </Typography>

                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '14pt',  fontFamily: 'Readex Pro',
                                    fontWeight: 'bold', }}>
                                Last Used Data
                            </Typography>
                        </Grid>
                    </Grid> */}
                </Grid>

            </Grid>
        </div>
    )
}

export default UtilityForManage