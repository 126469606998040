import React, { useState, useRef, useEffect } from 'react';

const TemperatureGauge = ({ handleChangeSetPoint, setPoint = 20 }) => {
    // Convert setPoint (20-30°C) to temperature percentage (0-100%)    
    const setPointToPercentage = (temp) => {
        // Handle invalid or undefined setPoint
        if (!temp || isNaN(temp)) return 0;
        // Clamp the temperature between 20-30°C
        const clampedTemp = Math.min(Math.max(temp, 20), 30);
        return ((clampedTemp - 20) * 10); // Convert to percentage
    };

    // Initialize temperature state with setPoint value
    const [temperature, setTemperature] = useState(setPointToPercentage(setPoint));
    const [isDragging, setIsDragging] = useState(false);
    const svgRef = useRef(null);

    // Update temperature when setPoint prop changes
    useEffect(() => {
        const newTemp = setPointToPercentage(setPoint);
        setTemperature(newTemp);
    }, [setPoint]);

    // Update the size constants
    const size = 240; // Reduced from 280
    const strokeWidth = 20; // Slightly reduced from 24
    const radius = (size - strokeWidth - 30) / 2; // Reduced padding from 40 to 30
    const center = size / 2;
    const circumference = 2 * Math.PI * radius;

    // Calculate the coordinates for the blue dot
    const angle = (temperature / 100) * 2 * Math.PI + Math.PI / 2;
    const dotX = center + radius * Math.cos(angle);
    const dotY = center + radius * Math.sin(angle);

    // Calculate the dash array for the progress
    const dashArray = circumference;
    const dashOffset = circumference * (1 - temperature / 100);

    const handleGaugeChange = (value) => {
        // Convert percentage (0-100) to temperature (20-30°C)
        // Math.round ensures we only get integers
        const newValue = Math.round(value * 0.1 + 20);
        setTemperature(value);
        handleChangeSetPoint(newValue);
    };

    const updateTemperatureFromEvent = (clientX, clientY) => {
        if (!svgRef.current) return;

        const svgRect = svgRef.current.getBoundingClientRect();
        const svgX = clientX - svgRect.left;
        const svgY = clientY - svgRect.top;

        const dx = svgX - center;
        const dy = svgY - center;

        let angle = Math.atan2(dy, dx) - Math.PI / 2;
        if (angle < 0) angle += 2 * Math.PI;

        let newTemp = (angle / (2 * Math.PI)) * 100;
        newTemp = Math.max(0, Math.min(100, newTemp));

        handleGaugeChange(newTemp);
    };

    // Event handlers
    const handleMouseDown = () => setIsDragging(true);
    const handleMouseUp = () => setIsDragging(false);
    const handleMouseMove = (e) => {
        if (!isDragging) return;
        updateTemperatureFromEvent(e.clientX, e.clientY);
    };
    const handleTouchMove = (e) => {
        if (!isDragging || !e.touches[0]) return;
        updateTemperatureFromEvent(e.touches[0].clientX, e.touches[0].clientY);
    };
    const handleClick = (e) => updateTemperatureFromEvent(e.clientX, e.clientY);

    // Event listener setup
    useEffect(() => {
        const handlers = {
            mousemove: handleMouseMove,
            mouseup: handleMouseUp,
            touchmove: handleTouchMove,
            touchend: handleMouseUp
        };

        Object.entries(handlers).forEach(([event, handler]) => {
            document.addEventListener(event, handler);
        });

        return () => {
            Object.entries(handlers).forEach(([event, handler]) => {
                document.removeEventListener(event, handler);
            });
        };
    }, [isDragging]); // Only re-run if isDragging changes

    const generateTicks = () => {
        const ticks = [];
        const tickCount = 60;
        const tickLength = 12; // Reduced from 16
        const tickPadding = 6; // Reduced from 8

        for (let i = 0; i < tickCount; i++) {
            const tickAngle = (i / tickCount) * 2 * Math.PI + Math.PI / 2;
            const isInActiveRange = i / tickCount <= temperature / 100;

            const innerRadius = radius + strokeWidth / 2 + tickPadding;
            const outerRadius = innerRadius + tickLength;

            const x1 = center + innerRadius * Math.cos(tickAngle);
            const y1 = center + innerRadius * Math.sin(tickAngle);
            const x2 = center + outerRadius * Math.cos(tickAngle);
            const y2 = center + outerRadius * Math.sin(tickAngle);

            ticks.push(
                <line
                    key={i}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={isInActiveRange ? "#0c80cd" : "#e0e0e0"}
                    strokeWidth={1.5}
                    opacity={isInActiveRange ? 1 : 0.3}
                />
            );
        }
        return ticks;
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <svg
                ref={svgRef}
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                onClick={handleClick}
                className="cursor-pointer touch-none"
            >
                {/* Background circle */}
                <circle
                    cx={center}
                    cy={center}
                    r={radius}
                    fill="none"
                    stroke="#f0f0f0"
                    strokeWidth={strokeWidth}
                />

                {/* Progress circle */}
                <circle
                    cx={center}
                    cy={center}
                    r={radius}
                    fill="none"
                    stroke="#0c80cd"
                    strokeWidth={strokeWidth}
                    strokeDasharray={dashArray}
                    strokeDashoffset={dashOffset}
                    transform={`rotate(90 ${center} ${center})`}
                    strokeLinecap="butt"
                />

                {/* Tick marks */}
                {generateTicks()}

                {/* Control dot - reduced size */}
                <circle
                    cx={dotX}
                    cy={dotY}
                    r={10} // Reduced from 14
                    fill="#0c80cd"
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                    className="cursor-grab active:cursor-grabbing"
                    filter="url(#shadow)"
                />

                {/* Shadow filter */}
                <defs>
                    <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                        <feOffset dx="0" dy="0" result="offsetblur" />
                        <feFlood floodColor="#0c80cd" floodOpacity="0.3" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
            </svg>
        </div>
    );
};

export default TemperatureGauge;
