import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography } from '@mui/material';

const OutsideTempSupplyTemp = ({ chartData }) => {
    const categories = chartData.map(data => data.time);
    const outTempData = chartData.map(data => data.outTemp);
    const supplyData = chartData.map(data => data.supply);
    const prevOutTempData = chartData.map(data => data.prevOutTemp);
    const prevSupplyData = chartData.map(data => data.prevSupply);

    const chartOptions = {
        chart: {
            type: 'line',
            zoom: {
                enabled: true
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    color: '#333'
                }
            },
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            }
        },
        yaxis: {
            max: 45,
            labels: {
                formatter: (value) => value.toFixed(2),
                style: {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    color: '#333'
                }
            },
            tickAmount: 5
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true,
            borderColor: '#e7e7e7',
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
        title: {
            show: false
        },
        tooltip: {
            theme: 'light'
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            labels: {
                colors: '#333'
            }
        }
    };

    const chartSeries = [
        {
            name: 'Outdoor Air Temperature',
            data: outTempData,
            color: '#02316a'
        },
        {
            name: 'Supply Air Temperature',
            data: supplyData,
            color: '#36b1e2'
        },
        {
            name: 'Prev Outdoor Air Temperature',
            data: prevOutTempData,
            color: '#FF8C00'
        },
        {
            name: 'Prev Supply Air Temperature',
            data: prevSupplyData,
            color: '#008000'
        }
    ];

    return (
        <div>
            <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={400} />
        </div>
    );
}

export default OutsideTempSupplyTemp;