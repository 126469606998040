import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SupplyTempSetPointTemp = ({ chartData2 }) => {
    const categories = chartData2.map(data => data.time);
    const supplyData = chartData2.map(data => data.supply);
    const setPointData = chartData2.map(data => data.setPoint);
    const prevSupplyData = chartData2.map(data => data.prevSupply);
    const prevSetPointData = chartData2.map(data => data.prevSetPoint);



    const chartOptions = {
        chart: {
            type: 'line',
            zoom: {
                enabled: true
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    color: '#333'
                }
            },
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            }
        },
        yaxis: {
            max: 45,
            labels: {
                formatter: (value) => value.toFixed(2),
                style: {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    color: '#333'
                }
            },
            tickAmount: 5
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true,
            borderColor: '#e7e7e7',
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
        title: {
            show: false
        },
        tooltip: {
            theme: 'light'
        }
    };

    const chartSeries = [
        {
            name: 'Supply Air Temperature',
            data: supplyData,
            color: '#36b1e2'
        },
        {
            name: 'Set Point Temperature',
            data: setPointData,
            color: '#02316a'
        },
        {
            name: 'Prev Supply Air Temperature',
            data: prevSupplyData,
            color: '#FF8C00'
        },
        {
            name: 'Prev Set Point Temperature',
            data: prevSetPointData,
            color: '#008000'
        }
    ];

    return (
        <div>
            <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={400} />
        </div>
    );
}

export default SupplyTempSetPointTemp;