import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { EnergyGraphShowData } from '../ApiService/LoginPageService';

const ChartForDashboard = ({ filterId, selectedData }) => {
  const [dateLabel, setDateLabel] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [activeButton, setActiveButton] = useState('today');
  const theme = useTheme();

  useEffect(() => {
    fetchData(activeButton);
  }, [filterId, selectedData, activeButton]);

  const fetchData = (duration) => {
    const deviceIdToSend = selectedData?.deviceId || null;

    EnergyGraphShowData({
      sortDataType: duration,
      type: filterId === "All" ? "" : filterId,
      deviceId: deviceIdToSend,
    }, handleGraphSuccess, handleGraphException);
    setActiveButton(duration);
  };

  const handleGraphSuccess = (dataObject) => {
    const isToday = dataObject?.message.includes("today");
    const isUntilNow = dataObject?.message.includes("until now");

    const splitDate = dataObject?.data?.map((data) => {
      if (isToday) return data.hour;
      if (isUntilNow) return data.year;
      return data.date;
    }) || [];

    const splitEnergy = dataObject?.data?.map((data) => {
      if (isToday) return data.energyConsum;
      return data.units;
    }) || [];

    setDateLabel(splitDate);
    setDataList(splitEnergy);
  };

  const handleGraphException = (errorObject, errorMessage) => {
    console.error("Error fetching graph data:", errorMessage, errorObject);
    setDateLabel([]);
    setDataList([]);
  };

  const chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    xaxis: {
      categories: dateLabel,
      labels: {
        show: true,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          color: '#333'
        }
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      title: {
        text: 'kW Used'
      },
      labels: {
        formatter: (value) => typeof value === 'number' ? value.toFixed(2) : value,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: '600',
          color: '#333'
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      colors: ['#36b1e2']
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#e7e7e7',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    title: {
      text: 'Energy Consumption (kW Used)',
      align: 'left',
      style: {
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: '600',
        color: '#333'
      }
    }
  };

  const chartSeries = [{
    name: 'Energy Consumption',
    data: dataList
  }];

  const buttonStyle = (duration) => ({
    fontSize: '10pt',
    fontFamily: 'Readex Pro',
    fontWeight: 'bold',
    borderRadius: '15px',
    backgroundColor: activeButton === duration ? '#02316a' : '#36b1e2',
    border: 'none',
    marginLeft: '2%',
    width: '125px',
    marginTop: '10px',
    color: 'white',
    '&:hover': {
      backgroundColor: activeButton === duration ? '#02316a' : '#2a8bbd'
    }
  });

  return (
    <div style={{ position: 'relative', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={350} />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{
          marginTop: theme.spacing(2),
        }}
      >
        <Button sx={buttonStyle('today')} onClick={() => fetchData('today')}>TODAY</Button>
        <Button sx={buttonStyle('week')} onClick={() => fetchData('week')}>THIS WEEK</Button>
        <Button sx={buttonStyle('month')} onClick={() => fetchData('month')}>THIS MONTH</Button>
        <Button sx={buttonStyle('year')} onClick={() => fetchData('year')}>THIS YEAR</Button>
        <Button sx={buttonStyle('untilNow')} onClick={() => fetchData('untilNow')}>UNTIL NOW</Button>
      </Grid>
    </div>
  );
};

export default ChartForDashboard;
