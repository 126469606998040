import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography } from '@mui/material';

const ChartForEnergyUse = ({ dateLable, dataList, pervdateLabel, pervdataList }) => {
  const categories = dateLable.length > 0 ? dateLable : [''];
  const allValues = [...dataList, ...pervdataList];
  const yMax = allValues.length > 0 ? Math.max(...allValues) : 0;
  const chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    xaxis: {
      categories: dateLable,
      labels: {
        show: true,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          color: '#333'
        }
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      min: 0,
      max: yMax,
      labels: {
        formatter: (value) => value.toFixed(2),
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: '600',
          color: '#333'
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      colors: ['#02316a', '#36b1e2'] // Colors for both datasets
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#e7e7e7',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    title: {
      show: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      labels: {
        colors: '#333'
      }
    }
  };

  const chartSeries = [
    {
      name: 'Energy Consumption Current',
      data: dataList
    },
    {
      name: 'Energy Consumption Comparison',
      data: pervdataList
    }
  ];

  return (
    <div>
      <Typography style={{ display: 'flex', fontSize: '18px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginLeft: '25px' }}>
        ENERGY USE (Kw Used)
      </Typography>
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={350} />
    </div>
  );
}

export default ChartForEnergyUse;