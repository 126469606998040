import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography } from '@mui/material';

const ChartForTodayData = ({ dateWaterLable, dataWaterList, prevWaterdataList }) => {
  const categories = dateWaterLable.length > 0 ? dateWaterLable : [''];

  const allValues = [...dataWaterList, ...prevWaterdataList];
  const yMax = allValues.length > 0 ? Math.max(...allValues) : 0;

  const chartOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          color: '#333'
        }
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      min: 0,
      max: yMax,
      labels: {
        formatter: (value) => (value !== null && value !== undefined) ? value.toFixed(2) : '',
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: '600',
          color: '#333'
        }
      },
      tickAmount: 5
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#e7e7e7',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    title: {
      show: false
    },
    tooltip: {
      theme: 'light'
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      labels: {
        colors: '#333'
      }
    }
  };

  const chartSeries = [
    {
      name: 'Water Consumption Current',
      data: dataWaterList.length > 0 ? dataWaterList : [null],
      color: '#02316a'
    },
    {
      name: 'Water Consumption Comparison',
      data: prevWaterdataList.length > 0 ? prevWaterdataList : [null],
      color: '#36b1e2'
    }
  ];

  return (
    <div>
      <Typography style={{ display: 'flex', fontSize: '18px', fontFamily: 'Readex Pro', fontWeight: 'bold', marginLeft: '25px' }}>
        WATER USE (Liters Used)
      </Typography>
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={400} />
    </div>
  );
};

export default ChartForTodayData;
