import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './StreetMap.css';
import red from './images/Location.png';
import gray from './images/Gray.png';
import grayRedDot from './images/GrayRdot.png';
import yellow from './images/yell.png';
import green from './images/green.png'
import { AlertMapPinData } from '../ApiService/LoginPageService';
import Select from 'react-select';

const greenIcon = new L.Icon({
    iconUrl: green,
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
});

const yellowIcon = new L.Icon({
    iconUrl: yellow,
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
});

const redIcon = new L.Icon({
    iconUrl: red,
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
});

const grayIcon = new L.Icon({
    iconUrl: gray,
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
});

const grayRedDotIcon = new L.Icon({
    iconUrl: grayRedDot,
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
});

const StreetMap = ({ filterId, setIsDashboard, setSelectedData }) => {
    const [mapData, setmapData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [customerNames, setCustomerNames] = useState([]);
    const mapRef = useRef(null);

    useEffect(() => {
        AlertMapPinData({
            type: filterId === 'All' ? "" : filterId
        }, handleMapSuccess, handleMapException);
    }, [filterId]);

    const handleMapSuccess = (dataObject) => {
        setmapData(dataObject.data);
        setFilteredData(dataObject.data);
    };

    const handleMapException = () => {
        console.error("Error fetching map data");
    };

    const handleCustomerClick = (location) => {
        setIsDashboard(1);
        setSelectedData(location);
    };

    // Prepare options for search with case-insensitive matching
    const searchOptions = mapData.map(item => ({
        value: item.deviceId,
        label: `${item.customer} (${item.deviceId})`,
        searchLabel: `${item.customer.toLowerCase()} ${item.deviceId.toLowerCase()}`, // Add searchLabel for matching
        data: item
    }));

    const deviceTypeOptions = [...new Set(mapData.map(item => item.deviceType))].map(type => ({
        value: type,
        label: type
    }));

    const customerOptions = [...new Set(mapData.map(item => item.customer))].map(customer => ({
        value: customer,
        label: customer
    }));

    // Custom filter function for react-select
    const customFilter = (option, searchText) => {
        const searchLower = searchText.toLowerCase();
        return option.data.searchLabel.includes(searchLower) ||
            option.data.value.toLowerCase().includes(searchLower) ||
            option.data.data.deviceType.toLowerCase().includes(searchLower);
    };

    // Filter function
    useEffect(() => {
        let filtered = mapData;

        if (selectedDevice) {
            filtered = filtered.filter(item => item.deviceId === selectedDevice.value);
        }

        if (selectedDeviceType) {
            filtered = filtered.filter(item => item.deviceType === selectedDeviceType.value);
        }

        if (selectedCustomer) {
            filtered = filtered.filter(item => item.customer === selectedCustomer.value);
        }

        setFilteredData(filtered);
    }, [selectedDevice, selectedDeviceType, selectedCustomer, mapData]);

    // Add the parseLocation function inside the component
    const parseLocation = (locationString) => {
        if (!locationString || locationString === '""' || locationString === '') {
            console.warn("Empty or invalid location string");
            return null;
        }

        try {
            let parsedLocation;
            // Check if the string is already parsed
            if (typeof locationString === 'string') {
                parsedLocation = JSON.parse(locationString);
            } else {
                parsedLocation = locationString;
            }

            // Extract coordinates, ensuring they're numbers
            const coordinates = {
                latitude: Number(parsedLocation.latitude),
                longitude: Number(parsedLocation.longitude),
                accuracy: parsedLocation.accuracy,
                speed: parsedLocation.speed,
                altitude: parsedLocation.altitude,
                bearing: parsedLocation.bearing,
                time: parsedLocation.time,
                provider: parsedLocation.provider
            };

            // Validate coordinates
            if (isNaN(coordinates.latitude) || isNaN(coordinates.longitude) ||
                coordinates.latitude < -90 || coordinates.latitude > 90 ||
                coordinates.longitude < -180 || coordinates.longitude > 180) {
                console.warn("Invalid coordinates:", coordinates);
                return null;
            }

            return coordinates;
        } catch (error) {
            console.error("Failed to parse location:", error);
            return null;
        }
    };

    // Handle search selection
    const handleDeviceSelect = (selected) => {
        setSelectedDevice(selected);
        if (selected) {
            const coordinates = parseLocation(selected.data.location);
            if (coordinates) {
                // Center map on selected location and zoom in
                if (mapRef.current) {
                    mapRef.current.setView(
                        [coordinates.latitude, coordinates.longitude],
                        15 // Zoom level when location is selected
                    );
                }
            }
        } else {
            // Reset to India view when search is cleared
            if (mapRef.current) {
                mapRef.current.setView([20.5937, 78.9629], 5);
            }
        }
    };

    // Reset all filters and map view
    const handleReset = () => {
        setSelectedDevice(null);
        setSelectedDeviceType(null);
        setSelectedCustomer(null);
        setFilteredData(mapData);

        // Reset map view to show all India
        if (mapRef.current) {
            mapRef.current.setView([20.5937, 78.9629], 5);
        }
    };

    return (
        <div style={styles.mainContainer}>
            <div style={styles.filterContainer}>
                <div style={styles.filterRow}>
                    {/* Search with Autocomplete */}
                    <div style={styles.filterItem}>
                        <Select
                            value={selectedDevice}
                            onChange={handleDeviceSelect}
                            options={searchOptions}
                            placeholder="🔍 Search customer or device..."
                            isClearable
                            filterOption={customFilter}
                            ignoreCase={true}
                            styles={customSelectStyles}
                            menuPortalTarget={document.body}
                        />
                    </div>

                    {/* Device Type Dropdown */}
                    <div style={styles.filterItem}>
                        <Select
                            value={selectedDeviceType}
                            onChange={setSelectedDeviceType}
                            options={deviceTypeOptions}
                            placeholder="📱 Device Type"
                            isClearable
                            styles={customSelectStyles}
                        />
                    </div>

                    {/* Customer Dropdown */}
                    <div style={styles.filterItem}>
                        <Select
                            value={selectedCustomer}
                            onChange={setSelectedCustomer}
                            options={customerOptions}
                            placeholder="👥 Customer"
                            isClearable
                            styles={customSelectStyles}
                        />
                    </div>

                    {/* Reset Button */}
                    <div style={styles.resetButtonContainer}>
                        <button
                            onClick={handleReset}
                            style={styles.resetButton}
                        >
                            🔄 Reset All
                        </button>
                    </div>
                </div>
            </div>

            {/* Map Container */}
            <div style={styles.mapWrapper}>
                <MapContainer
                    center={[20.5937, 78.9629]}
                    zoom={5}
                    scrollWheelZoom={true}
                    style={styles.map}
                    ref={mapRef}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {filteredData && filteredData.length > 0 && filteredData.map((location, index) => {
                        let icon;
                        switch (location.status) {
                            case 'All Ok': icon = greenIcon; break;
                            case 'Filter Missing': icon = yellowIcon; break;
                            case 'No Source Water': icon = redIcon; break;
                            case 'Communication Down': icon = grayIcon; break;
                            case 'Unit is OFF': icon = grayRedDotIcon; break;
                            default: icon = grayIcon;
                        }

                        const coordinates = parseLocation(location.location);

                        if (coordinates && coordinates.latitude && coordinates.longitude) {
                            // Create more significant offset based on index
                            const offset = 0.01; // Approximately 1km separation
                            const position = [
                                coordinates.latitude + (index * offset),
                                coordinates.longitude + (index * offset)
                            ];

                            return (
                                <Marker
                                    key={location.deviceId}
                                    position={position}
                                    icon={icon}
                                >
                                    <Tooltip
                                        direction="top"
                                        offset={[0, -20]}
                                        opacity={1}
                                        permanent={true}
                                        className="custom-tooltip"
                                    >
                                        <div className="tooltip-content">
                                            <strong style={{ fontSize: '14px' }}>{location.customer}</strong>
                                            <div style={{ color: '#666' }}>{location.deviceId}</div>
                                        </div>
                                    </Tooltip>
                                    <Popup>
                                        <div>
                                            <strong>{location.customer}</strong><br />
                                            <strong>Device ID:</strong> {location.deviceId}<br />
                                            <strong>Device Type:</strong> {location.deviceType}<br />
                                            <strong>Contact:</strong> {location.phoneNo}<br />
                                            <strong>Status:</strong> {location.status}<br />
                                            <strong>Coordinates:</strong> {coordinates.latitude.toFixed(6)}, {coordinates.longitude.toFixed(6)}<br />
                                            {coordinates.accuracy && <><strong>Accuracy:</strong> {coordinates.accuracy.toFixed(2)}m<br /></>}
                                        </div>
                                    </Popup>
                                </Marker>
                            );
                        }
                        return null;
                    })}
                </MapContainer>
            </div>
        </div>
    );
};

// Updated styles with more specific positioning and visibility
const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '90vh', // Adjusted height
        padding: '8px',
        backgroundColor: '#fff',
        position: 'relative',
        overflow: 'hidden',
    },
    filterContainer: {
        width: '100%',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        padding: '5px',
        marginBottom: '5px',
        zIndex: 10,
    },
    filterRow: {
        display: 'flex',
        gap: '8px',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    filterItem: {
        flex: 1,
        minWidth: '150px',
    },
    input: {
        width: '100%',
        padding: '5px 8px', // Minimal padding
        borderRadius: '4px',
        border: '1px solid #dee2e6',
        fontSize: '13px', // Smaller font
        backgroundColor: '#fff',
        boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.075)',
    },
    select: {
        width: '100%',
        padding: '5px 8px', // Minimal padding
        borderRadius: '4px',
        border: '1px solid #dee2e6',
        fontSize: '13px', // Smaller font
        backgroundColor: '#fff',
        cursor: 'pointer',
        boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.075)',
    },
    mapWrapper: {
        width: '100%',
        height: '80vh', // Adjusted height
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    map: {
        width: '100%',
        height: '100%',
        flexGrow: 1,
    },
    resetButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '80px', // Smaller width
    },
    resetButton: {
        padding: '5px 10px', // Minimal padding
        backgroundColor: '#f8f9fa',
        border: '1px solid #dee2e6',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '12px', // Smaller font
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        transition: 'all 0.2s ease',
        boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.075)',
        '&:hover': {
            backgroundColor: '#e9ecef',
            borderColor: '#adb5bd',
        },
        '&:active': {
            backgroundColor: '#dee2e6',
            transform: 'translateY(1px)',
        }
    },
};

// Update the inline styles for Select components to be even more compact
const customSelectStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: '4px',
        border: '1px solid #dee2e6',
        boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.075)',
        minHeight: '30px', // Very small height
        '&:hover': {
            border: '1px solid #adb5bd'
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 4px', // Minimal padding
        fontSize: '12px', // Smaller font
    }),
    input: (provided) => ({
        ...provided,
        margin: '0',
        padding: '0',
        textTransform: 'lowercase'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px', // Very small height
    }),
    indicator: (provided) => ({
        ...provided,
        padding: '4px', // Smaller padding for dropdown indicators
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#0052cc' : state.isFocused ? '#deebff' : 'white',
        color: state.isSelected ? 'white' : '#333',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: state.isSelected ? '#0052cc' : '#deebff'
        },
        padding: '4px 8px', // Minimal padding
        fontSize: '12px', // Smaller font
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 1400,
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    menuPosition: 'fixed',
    menuPortalTarget: document.body,
};

// REMOVE THE DYNAMIC STYLESHEET INJECTION LATER
// Enhance CSS styles with more specificity
const stylesheet = `
    .leaflet-container {
        width: 100% !important;
        height: 100% !important;
        z-index: 1 !important;
    }
    
    .map-container {
        height: 100% !important;
        width: 100% !important;
        position: relative !important;
        z-index: 1 !important;
    }
    
    .custom-tooltip {
        font-size: 11px !important;
        padding: 2px 4px !important;
        max-width: 200px !important;
        white-space: nowrap !important;
        z-index: 1000 !important;
    }
    
    .tooltip-content {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
    
    /* Fix for any potential rendering issues */
    .leaflet-pane {
        z-index: 1 !important;
    }
    
    .leaflet-control-container {
        z-index: 2 !important;
    }
    
    /* Make controls smaller */
    .leaflet-control-zoom a {
        width: 24px !important;
        height: 24px !important;
        line-height: 24px !important;
        font-size: 14px !important;
    }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = stylesheet;
document.head.appendChild(styleSheet);

export default StreetMap;